import {
  ArchiveRounded,
  GroupAdd,
  UnarchiveRounded,
} from "@mui/icons-material";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/snackbarReducer";
import makeAPIRequest from "../../../utils/makeAPIRequest";
import FullField from "../../Fields/FullField";
import FormPopup from "../../FormPopup/FormPopup";
import FormPopupHeader from "../../FormPopup/FormPopupHeader";
import OrangeButton from "../../Library/Buttons/OrangeButton/OrangeButton";
import MySelect from "../../Library/Select/MySelect";
import formatValue from "./formatValue";
import GenericRadar from "../../Library/Draft/GenericRadar";
import OrangeCheckBox from "../../Fields/CheckBox/OrangeCheckBox";
import { useOpenSnackbar } from "../../../pages/CreateOrEditChatbotLexPage";

export const fixDateInverse = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  const splitedDate = date.split("-");
  if (splitedDate.length !== 3) {
    return date;
  }
  const YYYY = splitedDate[0];
  const MM = splitedDate[1];
  const DD = splitedDate[2];
  if (YYYY.length !== 4) {
    return date;
  }
  if (MM.length !== 2) {
    return date;
  }
  if (DD.length !== 2) {
    return date;
  }
  return `${DD}-${MM}-${YYYY}`;
};

export const formatDateForResponse = (response) => {
  const date = response?.created ? new Date(response?.created) : new Date();

  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();

  let hours = date.getHours().toString();
  if (hours.length === 1) hours = `0${hours}`;

  let minutes = date.getMinutes().toString();
  if (minutes.length === 1) minutes = `0${minutes}`;

  return dd + "/" + mm + "/" + yyyy + " à " + hours + "h" + minutes;
};

export const displayPatientForResponse = (response, patientList) => {
  console.log(response);
  if (!response?.patientId) {
    if (response?.phone) {
      return response.phone;
    }
    return "Patient anonyme";
  }
  const patient = patientList?.find((e) => e?.id === response?.patientId);
  console.log(patient);
  if (!patient || !patient?.lastname || !patient?.firstname) {
    if (patient?.phone && patient?.id) {
      return patient.phone + " " + ` (${patient?.id})`;
    }
    return "Patient anonyme";
  }
  return patient?.lastname + " " + patient?.firstname + ` (${patient?.id})`;
};

const DisplayFormatedResponseSections: React.FC<any> = (props) => {
  const { formatedResponse, inputFilter, patientList } = props;
  const snackbarOpenUse = useOpenSnackbar();

  return (
    <Grid
      container
      direction="column"
      padding="10px"
      spacing="10px"
      wrap="nowrap"
    >
      <Grid item display="grid">
        <Grid
          container
          direction="row"
          gap="10px"
          justifyContent={"space-between"}
        >
          <Grid item display="grid">
            <Grid container direction="column" wrap="nowrap" spacing="1px">
              {!props.isAfterSended && (
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "rgb(11, 36, 60)",
                    }}
                  >
                    {displayPatientForResponse(formatedResponse, patientList)}
                  </span>
                </Grid>
              )}
              <Grid item display="grid">
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    color: "rgb(11, 36, 60)",
                  }}
                >
                  {formatDateForResponse(formatedResponse)}
                </span>
              </Grid>
            </Grid>
          </Grid>
          {formatedResponse?.scoreTotalFSUS !== undefined && (
            <Grid item display="grid">
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  color: "rgb(11, 36, 60)",
                }}
              >
                Score total F-SUS :{" "}
                {formatedResponse?.scoreTotalFSUS?.toFixed(2)}
              </span>
            </Grid>
          )}
        </Grid>
      </Grid>
      {formatedResponse?.sections &&
        Object.keys(formatedResponse?.sections).map((sectionTitle, index) => {
          return (
            <Grid item key={index} display="grid">
              <Grid container direction="column" spacing="10px" wrap="nowrap">
                <Grid item display="grid">
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      color: "#23595d",
                    }}
                  >
                    {sectionTitle ? sectionTitle : "Sans titre"}
                  </span>
                </Grid>
                <Grid item display="grid">
                  <Grid container direction="row" spacing="10px">
                    {formatedResponse?.sections?.[sectionTitle]
                      ?.sort(
                        (elementA, elementB) =>
                          elementB?.isExclusion - elementA?.isExclusion
                      )
                      ?.map((field, index) => {
                        const formatedValue = formatValue(field?.value);
                        return (
                          <Grid
                            item
                            key={field?.id}
                            display="grid"
                            alignItems="flex-start"
                          >
                            <Grid
                              container
                              direction="column"
                              wrap="nowrap"
                              boxShadow="3px 3px 2px 1px rgba(11, 36, 60, .3)"
                              border={`1px solid ${
                                field?.isExclusion ? "#FF3100" : "#0B243C"
                              }`}
                              borderRadius="10px"
                              maxWidth="500px"
                            >
                              <Grid
                                item
                                display="grid"
                                justifyContent="center"
                                padding="5px"
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    whiteSpace: "pre-line",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {field?.fieldTitle
                                    ? field.fieldTitle
                                    : "Sans titre"}
                                </span>
                              </Grid>
                              <Grid item display="grid">
                                <div
                                  style={{
                                    borderTop: `1px solid ${
                                      field?.isExclusion ? "#FF3100" : "#0B243C"
                                    }`,
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                display="grid"
                                justifyContent="center"
                                padding="4px"
                              >
                                <span
                                  onClick={() => {
                                    if (field?.fieldType === "piece_jointe") {
                                      makeAPIRequest(
                                        "get",
                                        `/questions/response/${formatedResponse?.id}`,
                                        undefined,
                                        "v3"
                                      )
                                        .then((res) => {
                                          console.log(res);
                                          const dataFields = res?.data?.fields;
                                          if (
                                            dataFields &&
                                            dataFields?.length > 0
                                          ) {
                                            const founded = dataFields.find(
                                              (x) => x?.id === field?.id
                                            );
                                            const base64 = founded?.value?.[1];
                                            if (
                                              base64 &&
                                              base64.includes(";base64,")
                                            ) {
                                              window.open(base64);
                                            } else {
                                              snackbarOpenUse.error(
                                                "Impossible de récupérer la pièce jointe."
                                              );
                                            }
                                          } else {
                                            snackbarOpenUse.error(
                                              "Impossible de récupérer la pièce jointe."
                                            );
                                          }
                                        })
                                        .catch((err) => {
                                          snackbarOpenUse.error(
                                            "Impossible de récupérer la pièce jointe."
                                          );
                                        });
                                    }
                                  }}
                                  style={{
                                    fontFamily: "Poppins",
                                    color: "#0B243C",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-line",
                                    cursor:
                                      field?.fieldType === "piece_jointe"
                                        ? "pointer"
                                        : "default",
                                  }}
                                >
                                  {formatedValue}
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

const DisplayFormatedResponse: React.FC<any> = (props) => {
  const {
    formatedResponse,
    responses,
    setResponses,
    patientList,
    checkboxValue,
    onCheckboxClick,
    isOnlyForPDF,
    isAfterSended,
  } = props;
  const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);
  const [patientSearched, setPatientSearched] = React.useState(null);
  const [openRegister, setOpenRegister] = React.useState("");
  const dispatch = useDispatch();

  const statment = (() => {
    if (formatedResponse?.comment?.length > 0) {
      return "just_comment";
    }
    if (
      !formatedResponse?.sections ||
      !(typeof formatedResponse?.sections === "object")
    ) {
      return undefined;
    }
    const sectionsTitles = Object.keys(formatedResponse?.sections);
    for (const sectionTitle of sectionsTitles) {
      for (const field of formatedResponse?.sections?.[sectionTitle]) {
        if (field?.isExclusion) {
          return "cond_arret";
        }
      }
    }
    return undefined;
  })();

  return (
    <>
      {openRegister && (
        <FormPopup>
          <Grid
            item
            display="grid"
            paddingTop="40px"
            paddingLeft="50px"
            paddingRight="50px"
            paddingBottom="40px"
          >
            <FormPopupHeader
              firstTitle={"Enregistrer"}
              secondTitle={"au nom d'un patient"}
              onClose={() => {
                setOpenRegister("");
              }}
            />
          </Grid>
          <Grid item display="grid">
            <Grid
              container
              display="flex"
              width="70%"
              justifySelf="center"
              direction="column"
              wrap="nowrap"
              paddingBottom="60px"
            >
              <Grid item display="grid">
                <FullField isMandatory={false} title="">
                  <MySelect
                    maxMenuHeight={100}
                    options={(() => {
                      const optionsTmp = [];
                      if (!patientList) return optionsTmp;
                      for (const patient of patientList) {
                        optionsTmp.push({
                          value: patient?.id,
                          label: patient?.lastname + " " + patient?.firstname,
                        });
                      }
                      return optionsTmp;
                    })()}
                    noOptionsMessage={() => "Aucun résultat"}
                    value={!patientSearched?.value ? null : patientSearched}
                    placeholder="Nom du patient"
                    onChange={(e) => {
                      setPatientSearched(e);
                    }}
                  />
                </FullField>
              </Grid>
              <Grid
                item
                display="grid"
                justifyContent="center"
                paddingTop="30px"
              >
                {!patientSearched?.value ? (
                  <OrangeButton
                    animation={true}
                    enable={false}
                    type="submit"
                    text={"Enregistrer"}
                  />
                ) : (
                  <OrangeButton
                    animation={true}
                    enable={true}
                    type="submit"
                    onClick={() => {
                      makeAPIRequest(
                        "post",
                        "/questions/response/register",
                        {
                          patient_id: patientSearched?.value,
                          response_id: formatedResponse?.id,
                        },
                        "v3"
                      )
                        .then((res) => {
                          const responsesTmp = [...responses];

                          const ind = responsesTmp?.findIndex(
                            (r) => r?.id === formatedResponse?.id
                          );
                          if (ind !== -1) {
                            responsesTmp[ind].patientId =
                              patientSearched?.value;
                            setResponses(responsesTmp);
                          }
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message: "La réponse a bien été modifiée",
                              type: "success",
                            })
                          );
                          setOpenRegister("");
                        })
                        .catch((err) => {
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message: "La réponse n'a pas pu être modifiée",
                              type: "error",
                            })
                          );
                          setOpenRegister("");
                        });
                    }}
                    text={"Enregistrer"}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormPopup>
      )}
      <Grid
        container
        direction="row"
        width={isOnlyForPDF ? (isAfterSended ? "900px" : "1200px") : undefined}
        border={isOnlyForPDF ? undefined : "1px solid #0B243C"}
        boxShadow={
          isOnlyForPDF ? undefined : "4px 4px 3px 2px rgba(11, 36, 60, .3)"
        }
        borderRadius={isOnlyForPDF ? undefined : "10px"}
        wrap="nowrap"
      >
        {!isOnlyForPDF && (
          <>
            <Grid item display="grid" padding={"5px"}>
              <Grid
                container
                direction="column"
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item display="grid">
                  <OrangeCheckBox
                    onClick={onCheckboxClick}
                    value={checkboxValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="grid">
              <div
                style={{
                  height: "100%",
                  borderLeft: "1px solid #0B243C",
                }}
              />
            </Grid>
          </>
        )}
        <Grid item display="grid" xs>
          <Grid container direction="column" wrap="nowrap">
            <Grid item display="grid">
              <DisplayFormatedResponseSections {...props} />
            </Grid>
            {statment !== undefined && (
              <Grid item display="grid" padding="10px">
                <Grid container direction="column" wrap="nowrap" gap="5px">
                  {formatedResponse?.comment?.length > 0 && (
                    <Grid item display="grid">
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          color: "#0B243C",
                        }}
                      >
                        Commentaire : {formatedResponse?.comment}
                      </span>
                    </Grid>
                  )}
                  <Grid item display="grid">
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        color: "#FF3100",
                      }}
                    >
                      {statment === `cond_arret`
                        ? `* Ce questionnaire a été interrompu par une condition
                      d'arrêt, la réponse est encadrée en rouge ci-dessus.`
                        : `* Ce questionnaire a été interrompu par un critère d'exclusion.`}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {formatedResponse?.graphs?.length > 0 && (
              <Grid item display="grid" paddingTop="10px" paddingBottom="10px">
                <Grid
                  container
                  direction="row"
                  gap="20px"
                  id={`graph-${formatedResponse?.id}`}
                >
                  {formatedResponse.graphs.reverse().map((graph, index) => {
                    return (
                      <Grid item xs display="grid" key={index}>
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          minWidth="400px"
                          overflow="auto"
                          maxWidth="600px"
                          gap="5px"
                          justifyContent="center"
                        >
                          <Grid item display="grid">
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                color: "#0B243C",
                                textAlign: "center",
                              }}
                            >
                              {graph?.title}
                            </span>
                          </Grid>
                          <Grid item display="grid">
                            <GenericRadar
                              legend={false}
                              min={graph?.min ? graph.min : 0}
                              max={graph?.max ? graph.max : undefined}
                              data={{
                                labels: graph.labels.map((x: any) => x?.title),
                                datasets: graph.items.map((x) => {
                                  return {
                                    data: x.data,
                                    label: x.label,
                                    hidden: false,
                                  };
                                }),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isOnlyForPDF && (
          <Grid item display="grid" paddingLeft="5px" paddingRight="5px">
            <div
              style={{
                height: "100%",
                borderLeft: "1px solid #0B243C",
              }}
            />
          </Grid>
        )}
        {!isOnlyForPDF && (
          <Grid item display="grid" justifyContent="center" paddingRight="5px">
            {isArchiveLoading && (
              <Grid
                container
                direction="column"
                wrap="nowrap"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress
                  style={{ color: "#657273", width: "24px", height: "24px" }}
                />
              </Grid>
            )}
            {!formatedResponse?.isArchived && !isArchiveLoading && (
              <Grid
                container
                direction="column"
                wrap="nowrap"
                spacing="20px"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item display="grid">
                  <Tooltip title="Archiver">
                    <IconButton
                      onClick={() => {
                        setIsArchiveLoading(true);
                        makeAPIRequest(
                          "post",
                          `/questions/response/${formatedResponse?.id}/archive`,
                          null,
                          "v3"
                        )
                          .then((res) => {
                            setIsArchiveLoading(false);
                            const responsesTmp = [...responses];

                            const ind = responsesTmp?.findIndex(
                              (r) => r?.id === formatedResponse?.id
                            );
                            if (ind !== -1) {
                              responsesTmp[ind].isArchived = true;
                              setResponses(responsesTmp);
                            }
                            dispatch(
                              openSnackbar({
                                duration: 3000,
                                message: "La réponse a bien été archivée",
                                type: "success",
                              })
                            );
                          })
                          .catch((err) => {
                            setIsArchiveLoading(false);
                            dispatch(
                              openSnackbar({
                                duration: 3000,
                                message: "Impossible d'archiver cette réponse",
                                type: "error",
                              })
                            );
                          });
                      }}
                      style={{ margin: "0", padding: "0" }}
                    >
                      <ArchiveRounded
                        fontSize="medium"
                        style={{ color: "rgb(11, 36, 60)" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item display="grid">
                  <Tooltip
                    title={
                      !formatedResponse?.patientId
                        ? "Changer le nom du patient"
                        : "Enregistrer au nom d'un patient"
                    }
                  >
                    <IconButton
                      onClick={() => {
                        setOpenRegister(formatedResponse?.id);
                      }}
                      style={{ margin: "0", padding: "0" }}
                    >
                      <GroupAdd
                        fontSize="medium"
                        style={{ color: "rgb(11, 36, 60)" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
            {formatedResponse?.isArchived && !isArchiveLoading && (
              <Grid
                item
                display="grid"
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip title="Désarchiver">
                  <IconButton
                    onClick={() => {
                      setIsArchiveLoading(true);
                      makeAPIRequest(
                        "post",
                        `/questions/response/${formatedResponse?.id}/unarchive`,
                        null,
                        "v3"
                      )
                        .then((res) => {
                          setIsArchiveLoading(false);
                          const responsesTmp = [...responses];

                          const ind = responsesTmp?.findIndex(
                            (r) => r?.id === formatedResponse?.id
                          );
                          if (ind !== -1) {
                            responsesTmp[ind].isArchived = false;
                            setResponses(responsesTmp);
                          }
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message: "La réponse a bien été désarchivée",
                              type: "success",
                            })
                          );
                        })
                        .catch((err) => {
                          setIsArchiveLoading(false);
                          dispatch(
                            openSnackbar({
                              duration: 3000,
                              message:
                                "Impossible de désarchiver cette réponse",
                              type: "error",
                            })
                          );
                        });
                    }}
                    style={{ margin: "0", padding: "0" }}
                  >
                    <UnarchiveRounded
                      fontSize="medium"
                      style={{ color: "rgb(11, 36, 60)" }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DisplayFormatedResponse;
